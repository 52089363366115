import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb, Form, Tab, Tabs, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import { LoginManager } from '../../core/login-manager';
import { ApiManager } from '../../core/api-manager';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../components/Breadcrumbs';
import { settings } from '../../config/settings';
import { parse } from '@fortawesome/fontawesome-svg-core';
import nodisp from '../../assets/nodisp.jpeg';


export default function Cart(props) {

    const navigate = useNavigate();
    const { p } = useParams();
    const page = parseInt(p);
    const nullIdCustomer = null;


    const [data, setData] = useState([]);
    const [azienda, setAzienda] = useState([]);
    const userInfo = LoginManager.getUserInfo();
    const [subtotale, setSubTotale] = useState(0);
    const [spedizione, setSpedizione] = useState(0);
    const [totale, setTotale] = useState(0);
    const [iva, setIva] = useState('');
    const [sdi, setSdi] = useState('');
    const [ragsoc, setRagsoc] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [notes, setNotes] = useState('');
    const [idCustomerOrder, setIdCustomerOrder] = useState(null);


    const [viaS, setViaS] = useState('');
    const [cittaS, setCittaS] = useState('');
    const [capS, setCapS] = useState('');
    const [provinciaS, setProvinciaS] = useState('');

    const [viaF, setViaF] = useState('');
    const [cittaF, setCittaF] = useState('');
    const [capF, setCapF] = useState('');
    const [provinciaF, setProvinciaF] = useState('');

    const [idVariant, setIdVariant] = useState(null);

    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(null);


    const [typeValue, setTypeValue] = useState(null);

    const typeOrder = [
        { "name": "Riassortimento", "value": 0 },
        { "name": "Nuova collezione", "value": 1 }
    ]


    const required = [ragsoc, iva, sdi, viaF, viaS, cittaF, cittaS, capF, capS, provinciaF, provinciaS];




    useEffect(() => {
        init();
        getAzienda(null);
        getAgentCustomer();
    }, [userInfo]);

    useEffect(() => {

        getShippingCost();

    }, [data]);


    async function getShippingCost() {


        const somma = data.reduce((acc, cur) => {
            return acc + (parseFloat(cur.price));
        }, 0);

        const sommaFormatted = somma.toFixed(2);
        setSubTotale(sommaFormatted);

        console.error(somma, sommaFormatted);

        const body = {
            amount: sommaFormatted
        }
        var response = await ApiManager.sendAuthenticatedRequest('/custom/get_shipping_cost', body);
        if (response.success === 1) {
            const totaleP = parseFloat(sommaFormatted) + parseFloat(response.body.price);
            setTotale(parseFloat(totaleP).toFixed(2));
            setSpedizione(response.body.price);
        }
    }

    async function init() {

        //console.warn(props.cartItem);

        var limit1 = props.cartItem + 10;

        var constraints = [
            {
                "fieldName": "idUser",
                "type": "value",
                "value": userInfo.id
            }
        ]
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var response = await DataManager.searchItems('customer_cart', 1, limit1, sorting, '', [], constraints)
        if (response.success === 1) {
            setData(response.body);
            console.warn(response.body)
        }
        else {
            toast.error('Errore. Riprova più tardi.')
        }
    }

    async function getAzienda(idCustomerAgent) {
        var constraints = [];
        if (userInfo.level === 2) {
            constraints = [
                {
                    "fieldName": "id",
                    "type": "value",
                    "value": idCustomerAgent
                }
            ]

        }
        else {
            constraints = [
                {
                    "fieldName": "idUser",
                    "type": "value",
                    "value": userInfo.id
                }
            ]

        }

        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var response = await DataManager.searchItems('customer', 1, 30, sorting, '', [], constraints)
        console.warn(response)
        setIdCustomerOrder(response.body[0].id)
        setAzienda(response.body);
        setIva(response.body[0].vat_number);
        setSdi(response.body[0].sdi);
        setRagsoc(response.body[0].ragione_sociale);
        setName(response.body[0].idUser.name);
        setSurname(response.body[0].idUser.surname);
        setEmail(response.body[0].idUser.email);
        setPhone(response.body[0].idUser.phone);
        setViaS(response.body[0].idShippingAddress[0].address);
        setCapS(response.body[0].idShippingAddress[0].cap);
        setCittaS(response.body[0].idShippingAddress[0].city);
        setProvinciaS(response.body[0].idShippingAddress[0].province);
        setViaF(response.body[0].idBillingAddress[0].address);
        setCapF(response.body[0].idBillingAddress[0].cap);
        setCittaF(response.body[0].idBillingAddress[0].city);
        setProvinciaF(response.body[0].idBillingAddress[0].province);
    }

    async function deleteItem(row) {
        console.log({ 'remove cart item': row.quantity });
        var response = await DataManager.deleteItem('customer_cart', row.id);
        if (response.success === 1) {
            init();
            props.setCartItem(props.cartItem - parseInt(row.quantity));
            toast.success('Prodotto rimosso dal carrello!', { theme: 'colored' })
        }
        else {
            toast.error('Errore. Riprova più tardi.')
        }

    }

    async function sendOrder() {

        let checkRequired = required.every(element => element !== '');

        if (userInfo.level === 2 && customerId === null) {
            toast.error("Prima di inviare l'ordine devi selezionare un cliente.", { theme: 'colored' });
        }
        else if (userInfo.level === 2 && typeValue === null) {
            toast.error("Prima di inviare l'ordine devi selezionare il tipo ordine", { theme: 'colored' });
        }
        else if (!checkRequired) {
            toast.error("Compila i campi mancanti.", { theme: 'colored' });
        }
        else {
            const row = []
            data.forEach(element => {
                let rowData = {
                    'model': element.model,
                    'fantasy': element.fantasy,
                    'variant': element.variant,
                    'code': element.code,
                    'quantity': element.quantity,
                    'listPrice': element.listPrice,
                    'discount': element.discount,
                    'price': element.price , //totale riga
                    'variante': element.variante
                }
                row.push(rowData);
            });

            var body = {};

            if (userInfo.level === 2) {


                var total_price = parseFloat(totale).toFixed(2);
                var tax_amount = parseFloat((parseFloat(totale).toFixed(2) * parseFloat('22.00').toFixed(2)) / 100).toFixed(2);
                var totaleDefinitivo = parseFloat(total_price) + parseFloat(tax_amount);


                body = {
                    'typeOrder': { 'value': parseInt(typeValue) },
                    'idCustomer': { 'id': customerId },
                    'ragione_sociale': ragsoc,
                    'vat_number': iva,
                    'sdi': sdi,
                    'shipping_address': viaS + ', ' + cittaS + ' ' + capS + ', ' + provinciaS,
                    'billing_address': viaF + ', ' + cittaF + ' ' + capF + ', ' + provinciaF,
                    'rows': row,
                    'total_price': total_price,
                    'status': { 'value': 0 },
                    'notes': notes,
                    'shipping_price': spedizione,
                    'subtotal': parseFloat(subtotale).toFixed(2),
                    'tax_rate': '22.00',
                    'tax_amount': parseFloat(tax_amount).toFixed(2),
                    'total_price_iva': parseFloat(totaleDefinitivo).toFixed(2)
                }

            }
            else {
                var total_price = parseFloat(totale).toFixed(2);
                var tax_amount = parseFloat((parseFloat(totale).toFixed(2) * parseFloat('22.00').toFixed(2)) / 100).toFixed(2);
                var totaleDefinitivo = parseFloat(total_price) + parseFloat(tax_amount);

                if (typeValue === null) {
                    body = {
                        'typeOrder': null,
                        'idCustomer': { 'id': idCustomerOrder },
                        'ragione_sociale': ragsoc,
                        'vat_number': iva,
                        'sdi': sdi,
                        'shipping_address': viaS + ', ' + cittaS + ' ' + capS + ', ' + provinciaS,
                        'billing_address': viaF + ', ' + cittaF + ' ' + capF + ', ' + provinciaF,
                        'rows': row,
                        'total_price': total_price,
                        'status': { 'value': 0 },
                        'notes': notes,
                        'shipping_price': spedizione,
                        'subtotal': parseFloat(subtotale).toFixed(2),
                        'tax_rate': '22.00',
                        'tax_amount': parseFloat(tax_amount).toFixed(2),
                        'total_price_iva': parseFloat(totaleDefinitivo).toFixed(2)
                    }

                } else {
                    body = {
                        'typeOrder': { 'value': parseInt(typeValue) },
                        'idCustomer': { 'id': idCustomerOrder },
                        'ragione_sociale': ragsoc,
                        'vat_number': iva,
                        'sdi': sdi,
                        'shipping_address': viaS + ', ' + cittaS + ' ' + capS + ', ' + provinciaS,
                        'billing_address': viaF + ', ' + cittaF + ' ' + capF + ', ' + provinciaF,
                        'rows': row,
                        'total_price': total_price,
                        'status': { 'value': 0 },
                        'notes': notes,
                        'shipping_price': spedizione,
                        'subtotal': parseFloat(subtotale).toFixed(2),
                        'tax_rate': '22.00',
                        'tax_amount': parseFloat(tax_amount).toFixed(2),
                        'total_price_iva': parseFloat(totaleDefinitivo).toFixed(2)
                    }
                }


            }

            console.warn('BODYYY', body)


            var response = await DataManager.insertItem('customer_order', body);
            if (response.success === 1) {
                if (userInfo.level === 2) {

                    if (parseInt(typeValue) === 0) { //riassortimento
                        var emailAddress = [settings.mail, email, userInfo.email];
                        emailAddress.forEach(element => {
                            var bodyMail = {
                                'emailTo': element,
                                'ragione_sociale': ragsoc
                            }
                            var responseEmail = ApiManager.sendAuthenticatedRequest('/custom/send_invio_ordine', bodyMail);
                        })

                    }
                    else if (parseInt(typeValue) === 1) { //nuova collezione

                        var total_price2 = parseFloat(totale).toFixed(2);
                        var tax_amount2 = parseFloat((parseFloat(totale).toFixed(2) * parseFloat('22.00').toFixed(2)) / 100).toFixed(2);
                        var totaleDefinitivo2 = parseFloat(total_price2) + parseFloat(tax_amount2);


                        var emailAddress = [settings.mail, email, userInfo.email];
                        emailAddress.forEach(element => {
                            var bodyMail = {
                                emailTo: element,
                                ragione_sociale: ragsoc,
                                rows: row,
                                total_price: parseFloat(totaleDefinitivo2).toFixed(2),
                                subtotal: parseFloat(subtotale).toFixed(2),
                                shipping_price: parseFloat(spedizione).toFixed(2),
                                notes: notes,
                                iva: tax_amount,
                                tax_rate: parseFloat('22,00').toFixed(2),
                            }
                            var responseEmail = ApiManager.sendAuthenticatedRequest('/custom/send_new_collection', bodyMail);
                            console.log(responseEmail)
                        })

                    }


                } else {

                    var total_price1 = parseFloat(totale).toFixed(2);
                    var tax_amount1 = parseFloat((parseFloat(totale).toFixed(2) * parseFloat('22.00').toFixed(2)) / 100).toFixed(2);
                    var totaleDefinitivo1 = parseFloat(total_price1) + parseFloat(tax_amount1);

                    console.log(parseFloat(total_price1), parseFloat(tax_amount1), totaleDefinitivo1)

                    var emailAddress = [settings.mail, email];
                    emailAddress.forEach(element => {
                        const bodyMail = {
                            'emailTo': element,
                            'ragione_sociale': ragsoc,
                            //'id_ordine': '',
                            'rows': row,
                            'total_price': totaleDefinitivo1,
                            'subtotal': parseFloat(subtotale).toFixed(2),
                            'shipping_price': spedizione,
                            //'payment_condition': '',
                            'notes': notes,
                            'iva': parseFloat(tax_amount1).toFixed(2),
                            'tax_rate': '22',
                        }
                        var responseEmail = ApiManager.sendAuthenticatedRequest('/custom/send_invio_ordine', bodyMail);
                    })


                }

                const body = {
                    idUser: userInfo.id
                }
                var responseDelete = await ApiManager.sendAuthenticatedRequest('/custom/delete_cart', body);
                if (responseDelete.success === 1) {
                    init();
                    props.setCartItem(0);
                    navigate('/invio_riuscito');
                }
            }
        }       

    }

    async function getAgentCustomer() {

        var sorting = {
            'field': 'ragione_sociale',
            'order': 'asc'
        }
        var constraints = [
            {
                "fieldName": "idAgent",
                "type": "value",
                "value": userInfo.id
            }
        ]
        var response1 = await DataManager.searchItems('customer', 1, 100, sorting, '', [], constraints);
        setCustomers(response1.body);

    }

    function changeCustomer(e) {
        console.log('e', e)
        if (e === 'null') {
            e = null
        }

        setCustomerId(e);
        getAzienda(e);
    }







    if (userInfo) {
        return (
            <>

                <Container className='d-flex align-items-center mt-3 mb-5'>
                    <Breadcrumbs title='Carrello'></Breadcrumbs>
                </Container>
                <Container fluid='md' className='mt-5 mb-5'>
                    <Row className='gap-5'>
                        <Col md='7'>
                            {userInfo && userInfo.level === 2 ?
                                <>
                                    <Row className='border border-light p-2 mb-3'>
                                        <h5>Tipo ordine</h5>
                                        <hr></hr>
                                        <Row>
                                            <Form.Select aria-label="Default select example" onChange={(e) => setTypeValue(e.target.value)}>
                                                <option value='null'>Seleziona tipo ordine</option>
                                                {typeOrder.map((item) => {
                                                    return (
                                                        <option value={item.value}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                        </Row>

                                    </Row>
                                    <Row className='border border-light p-2 mb-3'>
                                        <h5>Cliente</h5>
                                        <hr></hr>
                                        <Row>
                                            <Form.Select aria-label="Default select example" onChange={(e) => changeCustomer(e.target.value)}>
                                                <option value='null'>Seleziona cliente</option>
                                                {customers.map((item) => {
                                                    return (
                                                        <option value={item.id}>{item.ragione_sociale}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                        </Row>

                                    </Row>

                                </>

                                : null}

                            {userInfo && userInfo.level === 2 && customerId !== null ? <>
                                <Row className='border border-light p-2'>
                                    <h5>Informazioni personali</h5>
                                    <hr></hr>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formNome" >
                                                <Form.Control type="text" placeholder="Nome" value={name} onChange={e => setName(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCognome">
                                                <Form.Control type="text" placeholder="Cognome" value={surname} onChange={e => setSurname(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formEmail">
                                                <Form.Control type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formTelefono">
                                                <Form.Control type="text" placeholder="Telefono" value={phone} onChange={e => setPhone(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formIva">
                                                <Form.Control type="text" placeholder="Partita IVA" value={iva} onChange={e => setIva(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formSdi">
                                                <Form.Control type="text" placeholder="Codice SDI" value={sdi} onChange={e => setSdi(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Row>
                                <Row className='border border-light flex flex-col p-2 mt-3'>
                                    <h5>Indirizzo di spedizione</h5>
                                    <hr></hr>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formVia">
                                                <Form.Control type="text" placeholder="Via" value={viaS} onChange={e => setViaS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCap">
                                                <Form.Control type="text" placeholder="CAP" value={capS} onChange={e => setCapS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCitta">
                                                <Form.Control type="email" placeholder="Città" value={cittaS} onChange={e => setCittaS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formProvincia">
                                                <Form.Control type="text" placeholder="Provincia" value={provinciaS} onChange={e => setProvinciaS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Row>
                                <Row className='border border-light flex flex-col p-2 mt-3'>
                                    <h5>Indirizzo di fatturazione</h5>
                                    <hr></hr>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formVia">
                                                <Form.Control type="text" placeholder="Via" value={viaF} onChange={e => setViaF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCap">
                                                <Form.Control type="text" placeholder="CAP" value={capF} onChange={e => setCapF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCitta">
                                                <Form.Control type="email" placeholder="Città" value={cittaF} onChange={e => setCittaF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formProvincia">
                                                <Form.Control type="text" placeholder="Provincia" value={provinciaF} onChange={e => setProvinciaF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Row>
                            </> : null}
                            {userInfo && userInfo.level !== 2 ? <>
                                <Row className='border border-light p-2'>
                                    <h5>Informazioni personali</h5>
                                    <hr></hr>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formNome">
                                                <Form.Control type="text" placeholder="Nome" value={name} onChange={e => setName(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCognome">
                                                <Form.Control type="text" placeholder="Cognome" value={surname} onChange={e => setSurname(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formEmail">
                                                <Form.Control type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formTelefono">
                                                <Form.Control type="text" placeholder="Telefono" value={phone} onChange={e => setPhone(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formIva">
                                                <Form.Control type="text" placeholder="Partita IVA" value={iva} onChange={e => setIva(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formSdi">
                                                <Form.Control type="text" placeholder="Codice SDI" value={sdi} onChange={e => setSdi(e.target.value)} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Row>
                                <Row className='border border-light flex flex-col p-2 mt-3'>
                                    <h5>Indirizzo di spedizione</h5>
                                    <hr></hr>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formVia">
                                                <Form.Control type="text" placeholder="Via" value={viaS} onChange={e => setViaS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCap">
                                                <Form.Control type="text" placeholder="CAP" value={capS} onChange={e => setCapS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCitta">
                                                <Form.Control type="email" placeholder="Città" value={cittaS} onChange={e => setCittaS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formProvincia">
                                                <Form.Control type="text" placeholder="Provincia" value={provinciaS} onChange={e => setProvinciaS(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Row>
                                <Row className='border border-light flex flex-col p-2 mt-3'>
                                    <h5>Indirizzo di fatturazione</h5>
                                    <hr></hr>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formVia">
                                                <Form.Control type="text" placeholder="Via" value={viaF} onChange={e => setViaF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCap">
                                                <Form.Control type="text" placeholder="CAP" value={capF} onChange={e => setCapF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formCitta">
                                                <Form.Control type="email" placeholder="Città" value={cittaF} onChange={e => setCittaF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md='6'>
                                            <Form.Group className="mb-3" controlId="formProvincia">
                                                <Form.Control type="text" placeholder="Provincia" value={provinciaF} onChange={e => setProvinciaF(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Row>
                            </> : null}

                        </Col>
                        <Col className='p-3 border border-light'>
                            <h5>Carrello</h5>
                            <hr></hr>
                            {data.length === 0 ?
                                <p>Nessun articolo nel carrello</p> :
                                <div className='d-flex flex-column ms-2'>
                                    <table className='container width-fluid'>
                                        <tbody style={{ width:'100%'}}>
                                            {data.map(item => {
                                                return (
                                                    <tr className='align-middle' style={{ borderBottom:'solid 1rem transparent'}} key={item.id}>
                                                        <td style={{position:'relative'}}>
                                                            <Image src={item.image ? item.image : nodisp} style={{ maxWidth: '80px', height:'auto', aspectRatio:'1/1', objectFit:'cover', borderRadius:'9999px' }} className='width-auto bg-white border'></Image>
                                                                {/* <Badge pill bg="secondary" style={{  }}>
                                                                    {item.quantity}
                                                                </Badge> */}
                                                        </td>
                                                        <td className=''>
                                                            <p style={{ margin:'auto 0'}}><span style={{ fontWeight: '600' }}>{item.code} - Col.{item.fantasy_name}</span>
                                                                <br />
                                                                <span style={{ fontSize: '.8em', color: 'rgb(150,150,150)' }}><strong style={{ fontWeight: '600' }}>€{parseFloat(item.price).toFixed(2)}</strong> x {item.quantity}</span>
                                                            </p>
                                                        </td>
                                                        <td className=''>
                                                            <button style={{display:'inline-block'}} className='ms-4 p-2 bg-white border-0' onClick={() => deleteItem(item)}><FontAwesomeIcon className='me-2' icon='trash'></FontAwesomeIcon>{/* Rimuovi */}</button>
                                                        </td>
                                                    </tr>

                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <hr></hr>
                                    <div className='d-flex flex-row justify-content-between'>

                                        <p>Subtotale</p>
                                        <p>€{subtotale}</p>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p>Spedizione</p>
                                            {/* {subtotale < 180 ? <p style={{ fontSize: '14px' }}>*Spedizione gratuita per ordini superiori ai 180€</p> : null}  */}
                                            <p>€{spedizione}</p>
                                        </div>

                                        {subtotale < 180 ? <Alert variant='warning' className='p-2' style={{ fontSize: '14px', width: '100%', textAlign: 'center' }}>
                                            Spedizione gratuita per ordini superiori a 180€
                                        </Alert> : null}
                                    </div>

                                    <hr></hr>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <h4>Totale</h4>
                                        <p style={{ fontWeight: '600' }}>€{totale} (IVA esclusa)</p>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <p>Pagamento</p>
                                        <p>Da definire</p>
                                    </div>
                                    <Form className='mt-3'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Aggiungi note al tuo ordine </Form.Label>
                                            <Form.Control as="textarea" rows={3} value={notes} onChange={e => { setNotes(e.target.value) }} />
                                        </Form.Group>
                                    </Form>
                                    <Button variant='danger' style={{ color: 'white' }} onClick={sendOrder} >Invia ordine</Button>

                                </div>
                            }

                        </Col>
                    </Row>
                </Container>

            </>
        )


    }


};