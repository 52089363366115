import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Row, Col, ButtonGroup, Button, Image, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../core/data-manager';
import { ResourceManager } from '../../core/resource-manager';
import { LanguageProvider } from '../../core/language-provider';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import blogDefault from '../../assets/entry-default.jpg';
import { settings } from '../../config/settings';
import Breadcrumbs from '../../components/Breadcrumbs';
import nodisp from '../../assets/nodisp.jpeg';
import logo from '../../assets/logo.svg';

function ContainerItem({ data }) {
    
    const url = settings.ckdPath;

    return (
        <Container fluid='md' className='mt-5 mb-5'>
            <div className='container'>
                <div className='row row-cols-md-4 row-cols-2'>
                    {data.sort((a, b) => {
                        return parseInt(a.code) - parseInt(b.code);
                    }).map(item => {
                        return (
                            <Link to={`/dettaglio/${item.id}`}>
                                <div className='flex flex-col' key={item.id}>
                                    {item && item.photo && item.photo.entity ?
                                        <Image src={url + 'upload/' + item.photo.entity + '/' + item.photo.hash + '.' + item.photo.extension} style={{ width: '100%' }} className='mb-1' />
                                        :
                                        <Image src={nodisp} style={{ width: '100%', maxHeight: '300px' }}></Image>}
                                
                                    <p>{item.name}</p>
                                </div>
                            </Link>
                        )
                    })}
                </div>

            </div>
        </Container>
    );
}

function ContainerNoData() {
    return (
        <div style={{ height: '70vh' }} className='w-100 d-flex flex-column align-items-center justify-content-center'>
            {/* <img src={logo} alt="Annamaria" className='w-25 d-block m-4'/> */}
            <h2 className='text-center p-10'>Nessun prodotto disponibile</h2>
            <Button as={Link} to='/' variant='danger' className='m-4' style={{ color: 'white' }}>Torna alla home</Button>
        </div>
    )
}

export default function Collection() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');

    const { k } = useParams();
    const id = parseInt(k);

    const [data, setData] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [linea, setLinea] = useState(3);
    const [title, setTitle] = useState('');

    useEffect(() => {
        init();
    }, [id]);


    async function init() {

        setIsFetching(true);
        
        var constraints = [
            {
                "fieldName": "idLine",
                "type": "value",
                "value": id
            }
        ];
        var sorting = {
            'field': 'code',
            'order': 'asc'
        };
        
        DataManager.getItem('line', id)
        .then(response => {
            if (response.success !== 1) {
                throw new Error;
            }
            setTitle(response.body[0].name);
        })
        .catch(error => {
            setTitle('');
            console.error(error);
        });

        DataManager.searchItems('model', 1, 300, sorting, '', [], constraints)
        .then(response => {
            if (response.success !== 1) {
                setIsFetching(false);
                setErrors('Errore. Riprova più tardi.');
                throw new Error;
            }

            if (response.body.length > 0) {
                /* console.log({'data' : response.body});
                console.log({ 'data 1': JSON.stringify(response.body[0]) }); */
                setData(response.body);
                setTotalPages(response.pages);
                setIsFetching(false);
            } else {
                throw new Error;
            }
        })
        .catch(error => {
            setData([]);
            setIsFetching(false);
            setErrors('Errore. Riprova più tardi.');
            console.error(error);
        });

    }

    return (
        <>
            <Container className='d-flex align-items-center mt-3 mb-5'>
                <Breadcrumbs title={title}></Breadcrumbs>
            </Container>
            {
                !isFetching ? 
                    (
                        data.length > 0 ?
                        <ContainerItem data={data}></ContainerItem>
                        :
                        <ContainerNoData></ContainerNoData>
                    )
                    :
                    <div style={{ height: '70vh' }} className='w-100 d-flex flex-column align-items-center justify-content-center'>
                        <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
            }
        </>
    )


};