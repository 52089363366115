import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Alert, Spinner, Image, Row, Col, Button } from 'react-bootstrap';
import { LanguageProvider } from '../core/language-provider';
import { LoginManager } from '../core/login-manager';
import PromoSlider from '../components/PromoSlider';
import banner from '../assets/0000000.jpg';
import whatsapp from '../assets/whatsapp.svg';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';


export default function Home() {

    const [userInfo, setUserInfo] = useState(null);
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();


    useEffect(() => {

        async function init() {
            var u = LoginManager.getUserInfo()
            setUserInfo(u)
        }

        init()
    }, []);





    if (userInfo !== null) {
        return (
            <>
                <div style={{ position: 'relative', height: 'calc(100vh - 55px)' }} className='d-none d-md-block'>
                    <Image src={banner} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    <div style={{ position: 'absolute', zIndex: '500', top: '36%', left: '5%', width: '300px' }} className='d-lg-flex flex-column d-none'>
                        <h3>HOME&LIVING</h3>
                        <p>Scopri la collezione per dedicata Home e Living</p>
                        <Button  as={Link} to='/home-living' variant='danger' style={{ color: 'white' }}>Scopri di pi&ugrave;</Button>
                        <div className='d-flex align-items-center mt-3'>
                            <Image src={whatsapp} style={{ width: '50px' }}></Image>
                            <p className='mb-0'>331 222 9318</p>
                        </div>

                    </div>
                    <div style={{ position: 'absolute', zIndex: '500', top: '36%', right: '5%', textAlign: 'right', width: '350px' }} className='d-lg-flex flex-column justify-content-end d-none'>
                        <h3>FASHION</h3>
                        <p>Vai a vedere le nostre nuove collezioni Fashion</p>
                        <Button as={Link} to='/collezioni' variant='danger' style={{ color: 'white' }}>Scopri di pi&ugrave;</Button>
                        <div className='d-flex align-items-center mt-3 justify-content-end'>
                            <Image src={whatsapp} style={{ width: '50px' }}></Image>
                            <p className='mb-0'>375 542 5026</p>
                        </div>
                    </div>

                </div>
                <div className='d-flex flex-column d-md-none'>
                    <div className='w-100 text-center p-5 d-flex flex-column justify-content-center' style={{ backgroundColor: '#e4e3df', height: '45vh' }}>
                        <h3>HOME&LIVING</h3>
                        <p>Scopri la collezione per dedicata Home e Living</p>
                        <Button as={Link} to='/home-living' variant='danger' style={{ color: 'white' }}>Scopri di pi&ugrave;</Button>
                        <div className='d-flex align-items-center mt-3 justify-content-center'>
                            <Image src={whatsapp} style={{ width: '50px' }}></Image>
                            <p className='mb-0'>331 222 9318</p>
                        </div>
                    </div>
                    <div className='w-100 text-center p-5 d-flex flex-column justify-content-center' style={{ backgroundColor: '#c9b2a5', height: '45vh' }}>
                        <h3>FASHION</h3>
                        <p>Vai a vedere le nostre nuove collezioni Fashion</p>
                        <Button as={Link} to='/collezioni' variant='danger' style={{ color: 'white' }}>Scopri di pi&ugrave;</Button>
                        <div className='d-flex align-items-center mt-3 justify-content-center'>
                            <Image src={whatsapp} style={{ width: '50px' }}></Image>
                            <p className='mb-0'>375 542 5026</p>
                        </div>
                    </div>

                </div>
            </>




        )
    }
    else {
        return (
            <Container fluid="md" className='mt-5 mb-5'>
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="primary" />
                    <p className='text-primary'>{LanguageProvider.get('defaults.loading')}</p>
                </div>
            </Container>
        )
    }
};